import React, { useState, useEffect,useContext } from "react";
import Footer from "./Components/Footer";
import NavBar from "./Components/NavBar";
import Login from "./Pages/Login/Login";
import Questionnaire from "./Pages/Public/Questionnaire";
import Content from "./Components/Content";
import "./Assets/css/index.css";
import $ from "jquery"
import GlobalContext from "./Components/GlobalContext"
import { ToastContainer, toast } from 'react-toastify';
import {getUserRules,GetUser,GetAuthApprovalData } from './Components/Helper.js'
import {globalHost,globalPathLink,globalAttachmentLink,customStylesReadonly,customStyles } from './Components/Constant.js'
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  json
} from "react-router-dom";
import "./Assets/css/App.css"

function App() {
  const navigate=useNavigate();
  const globalContext = useContext(GlobalContext); 
  const [token, setToken] = useState(localStorage.getItem('authorizeInfos'));
  const [profileImage, setProfileImage] = useState("")
  const asyncSelectPlaceHolder="Type to search"
  const [userRule, setUserRule] = useState("")
  // const [userPort, setUserPort] = useState("")
  const [authApproval, setAuthApproval] = useState("")
  const [userId, setUserId] = useState("")
  const [questionnaireState, setQuestionnaire] = useState(false)
  const authInfo = JSON.parse(localStorage.getItem('authorizeInfos'));

 
  //listen to localstorage change, log in /out all tab
  window.addEventListener('storage', (event) => {
    if (event.key == "authorizeInfos") {
      if (event.newValue == null) {
      
        setToken(null)
        setUserRule("")
        navigate('/Login')
      } else {
       var parseInfo=JSON.parse(event.newValue)
     
        setToken(parseInfo)
        navigate('/')


      }
    }
  });

  useEffect(() => {
   $('body').addClass("hold-transition  layout-navbar-fixed  layout-top-nav  text-sm")
 
   return () => {
     
   }
 }, [])

  useEffect(() => {
    if(authInfo){
       $.ajax
       ({
          type: "POST",
          url:globalHost+globalPathLink + "site/check-access-token",
          dataType: 'json',
          headers: {
             "Authorization": "Basic " + btoa(authInfo.username + ":" + authInfo.access_token)
          },
          success: function (data ){
            setProfileImage(data.data.image)
            setUserId(data.data.id)
            GetUser(data.data.id,{ globalHost,authInfo,customStyles,customStylesReadonly,profileImage,globalPathLink}).then(res=>{
          
              //set default user port and user's company(for agent company)
              if(!localStorage.getItem('userAgentInfo')){
                localStorage.setItem('userAgentInfo', JSON.stringify({userPort:res[0]["Branch"]["PortCode"],userAgent:{Company:res[0].Company,Branch:res[0].Branch,CompanyContact:res[0].companyContact}}));
              }
              if(localStorage.getItem('userAgentInfo')!==JSON.stringify({userPort:res[0]["Branch"]["PortCode"],userAgent:{Company:res[0].Company,Branch:res[0].Branch,CompanyContact:res[0].companyContact}})){
                localStorage.setItem('userAgentInfo', JSON.stringify({userPort:res[0]["Branch"]["PortCode"],userAgent:{Company:res[0].Company,Branch:res[0].Branch,CompanyContact:res[0].companyContact}}));           
              }
             
          
        
             
            })

            if(!userRule){
              getUserRules(data.data.id,{ globalHost,authInfo,customStyles,customStylesReadonly,profileImage,globalPathLink}).then(res => {
                setUserRule(JSON.stringify(res.data))
                })
            }
            if(authInfo.ruleSetToken!==data.data.RuleSetToken ){
         
              getUserRules(data.data.id,{ globalHost,authInfo,customStyles,customStylesReadonly,profileImage,globalPathLink}).then(res => {
                setUserRule(JSON.stringify(res.data))
                })
                var tempAuth=authInfo
                tempAuth.ruleSetToken=data.data.RuleSetToken
                localStorage.setItem('authorizeInfos', JSON.stringify(tempAuth));
            }
         
            if(authApproval==""){
              GetAuthApprovalData({ globalHost,authInfo,customStyles,customStylesReadonly,profileImage,globalPathLink}, "universal").then(res => {
                setAuthApproval(JSON.stringify(res.data.data))
              })
            }
          
          
          },
          error: function(XMLHttpRequest, textStatus, errorThrown) {
             setToken(null)
             setUserRule("")
             localStorage.removeItem('authorizeInfos')
             localStorage.removeItem('userAgentInfo')
             navigate('/Login')
          }
       });
    }
    
   return () => {
   
   }
 }, [authInfo])


  useEffect(() => {
    $('body').addClass("hold-transition  layout-navbar-fixed  layout-top-nav  text-sm")
 
    return () => {
      
    }
  }, [])

  if (token == null) {
    // navigate('/Login')
    return (
      
      <>
        
        
        <GlobalContext.Provider value={{ globalHost,authInfo,customStyles,customStylesReadonly,authApproval,profileImage,userRule,globalPathLink,asyncSelectPlaceHolder,globalAttachmentLink}}>
        {!window.location.pathname.includes("public/questionnaire")?<Login setToken={setToken} />:""}
        <Routes>
          <Route path="/public/questionnaire" element={<Content Title="Questionnaire" ContentLink="Pages/Public/Questionnaire" />} />
          {/* <Route path="/Login" element={<Login setToken={setToken} />} /> */}
        </Routes>
        <ToastContainer />
        </GlobalContext.Provider>

      </>

    )

  }
  else {
      return (
        <GlobalContext.Provider value={{ globalHost,authInfo,customStyles,customStylesReadonly,authApproval,profileImage,userRule,globalPathLink,asyncSelectPlaceHolder,setToken,globalAttachmentLink}}>
        <div className="wrapper">
        <div className="PageOverlay mt-2 d-none"> <div className="PageSpinner"><i className="fas fa-3x fa fa-spinner fa-spin"></i><p className="loadingText"><b>Loading</b></p></div></div>
          <NavBar token={token} setToken={setToken} setUserRule={setUserRule} />
  
          <Footer />
          <ToastContainer />
        </div>
        </GlobalContext.Provider>
  
      )
   
    
 
  }


}




export default App