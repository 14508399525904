import React, { useState, useEffect, useContext } from 'react'
import { useForm, Controller, setValue, useFieldArray, set } from "react-hook-form";
import $ from "jquery"
import { ControlOverlay, PublicGetAllPortCode,sortArray,SavePublicData,ToastNotify} from '../../Components/Helper.js'
import GlobalContext from "../../Components/GlobalContext"
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select'
import 'react-toastify/dist/ReactToastify.css';

function Questionnaire() {
    const globalContext = useContext(GlobalContext);
    const [branch, setBranch] = useState([])
    const { register, handleSubmit, setValue, getValues, reset, control, trigger, watch, formState: { errors } } = useForm({ mode: "onChange" });
    const onSubmit = (data, event) => {

        event.preventDefault();
        const formdata = new FormData($("form")[0]);
        ControlOverlay(true)
        formdata.append("Questionnaire[Q1]",$("#RatingQ1").val())
        formdata.append("Questionnaire[Q2]",$("#RatingQ2").val())
        formdata.append("Questionnaire[Q3]",$("#RatingQ3").val())

        SavePublicData(globalContext, 'questionnaire', formdata).then(res => {
            if(res.data.message=="Questionnaire is save successfully"){
                ControlOverlay(false)
                ToastNotify("success", "Questionnaire save successfully")
            }else{
                ControlOverlay(false)
                ToastNotify("error", "Error")
            }
        })




    }
    const handleSubmitForm = (e) => {
        handleSubmit(onSubmit)(e);
    };
    useEffect(() => {
        PublicGetAllPortCode(globalContext,"area").then(res => {
            var arrayArea = []

            $.each(res.data.data, function (key, value) {
                arrayArea.push({ value: value.AreaUUID, label: value.PortCode })
            })
            setBranch(sortArray(arrayArea))

        })
        window.$("#RatingQ1").rating()
        window.$("#RatingQ2").rating()
        window.$("#RatingQ3").rating()

        return () => {

        }
    }, [])

    return (
        <form>

            <div className="col-md-12">
                <div className="card card-primary">
                    <div className="card-header text-center">Questionnaire</div>
                    <div className="card-body">

                        <div className="row">


                            <div className="col-xs-12 col-md-3">

                                <div className="form-group">
                                    <label className="control-label" >Branch
                                    </label>
                                    <Controller
                                        name="Questionnaire[Branch]"
                                        id="Branch"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                isClearable={true}
                                                {...register("Questionnaire[PortCode]")}
                                                value={value ? branch.find(c => c.value === value) : null}
                                                onChange={val => { val == null ? onChange(null) : onChange(val.value) }}
                                                options={branch}
                                                className="form-control Branch"
                                                classNamePrefix="select"
                                                styles={globalContext.customStyles}

                                            />
                                        )}
                                    />

                                    
                                </div>
                                </div>

                                <div className="col-xs-12 col-md-3">
                                    <div className="form-group">
                                        <label className="control-label">Name</label>

                                        <input defaultValue='' {...register("Questionnaire[Name]")} className={`form-control`} />
                                    </div>
                                </div>

                                <div className="col-xs-12 col-md-3">
                                    <div className="form-group">
                                        <label className="control-label">Contact Number</label>

                                        <input defaultValue='' {...register("Questionnaire[ContactNumber]")} className={`form-control`} />
                                    </div>
                                </div>

                                <div className="col-xs-12 col-md-3">
                                    <div className="form-group">
                                        <label className="control-label">Instructor</label>

                                        <input defaultValue='' {...register("Questionnaire[Instructor]")} className={`form-control`} />
                                    </div>
                                </div>

                                <div className="col-xs-12 col-md-12">
                                    <div className="form-group">
                                        <label className="control-label">Q1:What is your rating for the instructor?</label>

                                        <input id="RatingQ1" defaultValue="0" type="text" className="rating" data-min="0" data-max="5" data-step="1" data-size="sm" />
                                    </div>
                                </div>

                                <div className="col-xs-12 col-md-12">
                                    <div className="form-group">
                                        <label className="control-label">Q2:How would you rate the system's support in carrying out daily routine tasks?</label>

                                        <input id="RatingQ2" defaultValue="0" type="text" className="rating" data-min="0" data-max="5" data-step="1" data-size="sm" />
                                    </div>
                                </div>


                                <div className="col-xs-12 col-md-12">
                                    <div className="form-group">
                                        <label className="control-label">Q3:How would you rate the new system in comparison to the old system?</label>

                                        <input id="RatingQ3" defaultValue="0" type="text" className="rating" data-min="0" data-max="5" data-step="1" data-size="sm" />
                                    </div>
                                </div>


                                <div className="col-xs-12 col-md-12">
                                    <div className="form-group">
                                        <label className="control-label">Q4:Could you provide a list of the features present in the existing system that haven't been implemented in the new
                                            system if exist?</label>

                                        <textarea defaultValue='' {...register("Questionnaire[Q4]")} className={`form-control`} />
                                    </div>
                                </div>

                                <div className="col-xs-12 col-md-12">
                                    <div className="form-group">
                                        <label className="control-label">Q5:Could you please provide a list of the number of bugs encountered? I'd also appreciate a brief explanation of the
                                            modules in which they occur.</label>

                                        <textarea defaultValue='' {...register("Questionnaire[Q5]")} className={`form-control`} />
                                    </div>
                                </div>



                            </div>

                        </div>

                        <div className="text-center"><button type="button" className="btn btn-lg btn-primary mb-2" onClick={handleSubmitForm}>Save</button></div>





                    </div>




                </div>

        </form>

    )
}

export default Questionnaire